import { getEnv } from "./utils";

const USER = "user";
const SECOND = 1000;
const MINUTE = SECOND * 60;
const TOKEN_TTL = MINUTE * 19;
const IDLE_TIMEOUT_MIN = MINUTE * 10;
const PASSWORD_PLACEHOLDER = "********";
const EMAIL_PLACEHOLDER = "Enter an email address";
const VERIFICATION_CODE_PLACEHOLDER = "123456";
const FIRSTNAME_PLACEHOLDER = "Enter first name";
const LASTNAME_PLACEHOLDER = "Enter last name";
const PHONE_PLACEHOLDER = "Enter phone number";
const MINIMUM_LAYOVER_TIME = 2700000; // 45 mins
const DANGER_LAYOVER_TIME = 7.2e6; // 2 hrs
const AVERAGE_LAYOVER_TIME = 1.44e7; // 4 hrs

const ENV_MAPPER = {
  development: "dev",
  staging: "staging",
  test: "test",
  uat: "uat",
  production: "v3",
  qa: "qa",
  local: "local",
  integration: "intg",
};
const PASSWORD_STRENGTH = {
  MIN_LENGTH: 8,
  MIN_UPPERCASE: 1,
  MIN_LOWERCASE: 1,
  MIN_SPECIALCHARACTER: 1,
  MIN_NUMERIC: 1,
};

const APP_PERMISSION_MAPPER: any = {
  account: "app.account.view",
  workbench: "app.workbench.view",
  admin: "nf.app.platform.view",
  inventory: "app.inventory.view",
  inventoryTripView: "inventory.trip.view",
  inventoryTripManage: "inventory.trip.manage",
  userEdit: "users.self.manage",
  travelOrdersView: "travel.orders.view",
  travelOrdersManage: "travel.orders.manage",
  selfOrdersView: "travel.self.orders.view",
  selfOrdersBookedManage: "travel.self.orders.booked.manage",
  selfOrdersTicketedManage: "travel.self.orders.paid.manage",
  passengersView: "travel.passengers.view",
  passengersManage: "travel.passengers.manage",
  transactionManage: "travel.transactions.manage",
  transactionView: "travel.transactions.view",
  downloadTrace: "users.txns.manage",
};

const AUTH_TOKEN =
  getEnv() !== ENV_MAPPER.production ? "authToken" : "ssoToken";

const REFRESH_TOKEN = "refreshToken";
const DATE_TIME_FORMATE = "YYYY-MM-DDTHH:mm:ss";
const DATE_TIME_FORMATE_SHOW = "DD MMM, yyyy HH:mm";
const DATE_FORMATE_SHOW = "DD MMM, yyyy";
const TIME_FORMATE_SHOW = "HH:mm";
const JOURNEY_REF = "NFJRN";
const MIN_DATE_YEAR = "2022/01/01";
const INVENTORY_LIST_QUERY_KEY = "inventoryListQuery";
const LOCATION_PATHNAME_KEY = "locationPathName";
const INVALID_VALUE = "Invalid value";

export {
  USER,
  TOKEN_TTL,
  AUTH_TOKEN,
  ENV_MAPPER,
  REFRESH_TOKEN,
  IDLE_TIMEOUT_MIN,
  APP_PERMISSION_MAPPER,
  PASSWORD_PLACEHOLDER,
  PASSWORD_STRENGTH,
  EMAIL_PLACEHOLDER,
  VERIFICATION_CODE_PLACEHOLDER,
  FIRSTNAME_PLACEHOLDER,
  LASTNAME_PLACEHOLDER,
  PHONE_PLACEHOLDER,
  MINIMUM_LAYOVER_TIME,
  DANGER_LAYOVER_TIME,
  AVERAGE_LAYOVER_TIME,
  DATE_TIME_FORMATE,
  DATE_TIME_FORMATE_SHOW,
  TIME_FORMATE_SHOW,
  DATE_FORMATE_SHOW,
  JOURNEY_REF,
  MIN_DATE_YEAR,
  INVENTORY_LIST_QUERY_KEY,
  LOCATION_PATHNAME_KEY,
  INVALID_VALUE,
};
export const TRIP_TYPE = ["One Way", "Round Trip", "Multi-City"];
export const FLIGHT_CLASS = ["Economy", "Business", "First/Premium"];
export const JOURNEY_DEP_ARR_WARNING =
  "Please note that the journey's darparture time must be after the previous journey's arrival time to ensure accurate scheduling. Kindly double-check your input before proceeding";
export const SEGMENT_DEP_ARR_WARNING =
  "Please note that the arrival time must be after the departure time to ensure accurate scheduling. Kindly double-check your input before proceeding";
