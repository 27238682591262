export const CMS_ITEMS = {
  STATIONS: "stations",
};

export const CMS_PARAMS = {
  stations: {
    fields:
      "type,station_name_en,location_code,sub_type,city_name_en,iso_country",
    deep: "*",
  },
};
