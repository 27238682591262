import { fetchQuery } from "utils/relay";
import { emptyProxyObject } from "utils/utils";
import { Action, Thunk, thunk, action } from "easy-peasy";
import { CMS_ITEMS, CMS_PARAMS } from "utils/cmsConstants";
import axios from "axios";

export interface ItemModel {
  item: any;
  items: any[];
  clearItem: Action<ItemModel>;
  clearItems: Action<ItemModel>;
  setItem: Action<ItemModel, any>;
  setItems: Action<ItemModel, any[]>;
  modeOfTransport: any;
  setModeOfTransport: Action<ItemModel, any>;
  fetchAirports: Thunk<ItemModel, any>;
  setAirports: Action<ItemModel, any>;
  airports: any[];
  // fetchItem: Thunk<ItemModel, any>;
  // fetchItems: Thunk<ItemModel, any>;
}

const ItemStore: ItemModel = {
  item: emptyProxyObject,
  items: [],
  modeOfTransport: null,
  airports: [],

  clearItem: action((state) => {
    state.item = emptyProxyObject;
  }),

  clearItems: action((state) => {
    state.items = [];
  }),

  setItem: action((state, item) => {
    state.item = item;
  }),

  setItems: action((state, items) => {
    state.items = items;
  }),

  setModeOfTransport: action((state, item) => {
    state.modeOfTransport = item;
  }),

  setAirports: action((state, items) => {
    state.airports = items;
  }),

  fetchAirports: thunk(async (actions, value, helpers) => {
    const modeOfTransport = helpers.getState().modeOfTransport;

    const stationParams = {
      ...CMS_PARAMS.stations,
      "filter[_and][0][type]":
        modeOfTransport && modeOfTransport === "Helicopter" ? null : "airport",
      "filter[_and][1][_or][0][location_code][_icontains]": value,
      "filter[_and][1][_or][1][station_name_en][_icontains]": value,
      "filter[_and][1][_or][2][city_name_en][_icontains]": value,
      "filter[_and][2][status][_eq]": "published",
      sort: "location_code",
      // "limit":30,
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CMS_URL}/items/${CMS_ITEMS.STATIONS}`,
        {
          params: stationParams,
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_CMS_TOKEN}`,
          },
        }
      );

      const responseData = response.data.data;
      actions.setAirports(responseData || []);
    } catch (e) {
      console.log(e);
    }
  }),
  // fetchItem: thunk((actions, { query, variables }) => {
  //     actions.clearItem();
  //     return fetchQuery(query, variables).then((itemWrapper: any) => {
  //         const key = Object.keys(itemWrapper)[0];
  //         actions.setItem(itemWrapper[key]);
  //     });
  // }),

  // fetchItems: thunk((actions, { query, variables }) => {
  //     actions.clearItems();
  //     return fetchQuery(query, variables).then((itemsWrapper: any) => {
  //         const key = Object.keys(itemsWrapper)[0];
  //         actions.setItems(itemsWrapper[key]);
  //     });
  // }),
};

export default ItemStore;
